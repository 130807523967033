<template>
  <v-card-text>
    <div class="d-flex">
      <v-avatar>
        <img :src="reported.avatar_permanent_url" />
      </v-avatar>
      <div class="ml-3">
        <label class="d-block">{{ reported.full_name }}</label>
        <label class="d-block">{{ reported.email }}</label>
      </div>
    </div>
  </v-card-text>
</template>
<script>
import get from 'lodash/get'
import head from 'lodash/head'

export default {
  props: ['report'],

  computed: {
    authorAvatar(vm) {
      return get(vm.report, 'reportable.author.avatar_permanent_url')
    },
    reported(vm) {
      let item = head(
        vm.report.reportable.members.filter((member) => {
          return vm.report.reporter.id !== member.model_id
        })
      )
      return get(item, 'model')
    },
  },
}
</script>
